// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconLocation(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Location";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsx("path", {
                    d: "M8,15.2 C4.26666667,13.0007734 2.4,10.1207734 2.4,6.56 C2.4,3.37883984 4.9072054,0.8 8,0.8 C11.0927946,0.8 13.6,3.37883984 13.6,6.56 C13.6,10.1207734 11.7333333,13.0007734 8,15.2 Z M8,9.44 C9.5463973,9.44 10.8,8.15058008 10.8,6.56 C10.8,4.96941992 9.5463973,3.68 8,3.68 C6.4536027,3.68 5.2,4.96941992 5.2,6.56 C5.2,8.15058008 6.4536027,9.44 8,9.44 Z",
                    fill: Icon.mapColor(props.color)
                  })
            });
}

var make = IconLocation;

export {
  make ,
}
/* Icon Not a pure module */
