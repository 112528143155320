// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Api from "../../../api/Api.res.js";
import * as Url from "../../../utils/Url.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as React from "react";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as $$Location from "../../../models/Location.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as Routes_Product from "../../../routes/common/Routes_Product.res.js";
import * as ColocationProduct from "../../../models/ColocationProduct.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardProductEditorHeader from "../../common/dashboard/DashboardProductEditorHeader.res.js";
import * as DashboardProductEditorLayout from "../../common/dashboard/DashboardProductEditorLayout.res.js";
import * as DashboardProductEditorActionBar from "../../common/dashboard/DashboardProductEditorActionBar.res.js";
import * as DashboardProductEditorContextMenu from "../../common/dashboard/DashboardProductEditorContextMenu.res.js";
import * as DashboardProductEditorLocationTab from "../../common/dashboard/DashboardProductEditorLocationTab.res.js";
import * as DashboardProductEditorNoLocations from "../../common/dashboard/DashboardProductEditorNoLocations.res.js";
import * as DashboardProductEditorUnarchiveLink from "../../common/dashboard/DashboardProductEditorUnarchiveLink.res.js";
import * as DashboardProductEditorActiveStatusToggle from "../../common/dashboard/DashboardProductEditorActiveStatusToggle.res.js";

function DashboardEditProductLocationTab$DashboardEditProductLocationTab(props) {
  var locations = props.locations;
  var product = props.product;
  var initialState = React.useMemo((function () {
          return {
                  product: product,
                  status: "Editing",
                  location: Belt_Option.map(product.location, (function ($$location) {
                          return $$location.id;
                        })),
                  submissionError: false
                };
        }), []);
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action === "object") {
            if (action.TAG === "UpdateProduct") {
              return {
                      TAG: "Update",
                      _0: {
                        product: action._0,
                        status: state.status,
                        location: state.location,
                        submissionError: state.submissionError
                      }
                    };
            } else {
              return {
                      TAG: "Update",
                      _0: {
                        product: state.product,
                        status: state.status,
                        location: Caml_option.some(action._0),
                        submissionError: state.submissionError
                      }
                    };
            }
          }
          switch (action) {
            case "Submit" :
                var match = state.location;
                var match$1 = state.status;
                if (match === undefined) {
                  return "NoUpdate";
                }
                if (match$1 !== "Editing") {
                  return "NoUpdate";
                }
                var locationId = Caml_option.valFromOption(match);
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          product: state.product,
                          status: "Submitting",
                          location: state.location,
                          submissionError: state.submissionError
                        },
                        _1: (function (param) {
                            var dispatch = param.dispatch;
                            $$Promise.wait(Api.updateProductLocation(product.id, locationId), (function (x) {
                                    if (x.TAG === "Ok") {
                                      return dispatch("GoToNextTab");
                                    }
                                    SentryLogger.error1({
                                          rootModule: "DashboardEditProductLocationTab",
                                          subModulePath: {
                                            hd: "DashboardEditProductLocationTab",
                                            tl: /* [] */0
                                          },
                                          value: "make",
                                          fullPath: "DashboardEditProductLocationTab.DashboardEditProductLocationTab.make"
                                        }, "DashboardEditProductLocationTab::Submit::Error", [
                                          "Error",
                                          x._0
                                        ]);
                                    dispatch("FailSubmission");
                                  }));
                          })
                      };
            case "GoToNextTab" :
                return {
                        TAG: "SideEffects",
                        _0: (function (param) {
                            Url.visit(Routes_Product.Dashboard.editAssets(product.id));
                          })
                      };
            case "GoToPreviousTab" :
                return {
                        TAG: "SideEffects",
                        _0: (function (param) {
                            Url.visit(Routes_Product.Dashboard.editGeneral(product.id));
                          })
                      };
            case "FailSubmission" :
                return {
                        TAG: "Update",
                        _0: {
                          product: state.product,
                          status: "Editing",
                          location: state.location,
                          submissionError: true
                        }
                      };
            
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var tmp;
  if (locations.length !== 0) {
    var match$1 = state.product.status;
    var tmp$1;
    var exit = 0;
    switch (match$1) {
      case "Active" :
          var match$2 = state.status;
          tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx(DashboardProductEditorActiveStatusToggle.make, {
                        productId: product.id,
                        status: "Active",
                        busy: match$2 !== "Editing"
                      }),
                  JsxRuntime.jsx(DashboardProductEditorContextMenu.make, {
                        product: state.product,
                        onUpdated: (function (product) {
                            dispatch({
                                  TAG: "UpdateProduct",
                                  _0: product
                                });
                          })
                      })
                ]
              });
          break;
      case "Disabled" :
          var match$3 = state.status;
          tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx(DashboardProductEditorActiveStatusToggle.make, {
                        productId: product.id,
                        status: "Disabled",
                        busy: match$3 !== "Editing"
                      }),
                  JsxRuntime.jsx(DashboardProductEditorContextMenu.make, {
                        product: state.product,
                        onUpdated: (function (product) {
                            dispatch({
                                  TAG: "UpdateProduct",
                                  _0: product
                                });
                          })
                      })
                ]
              });
          break;
      case "Draft" :
      case "Expired" :
          exit = 1;
          break;
      case "Archived" :
          tmp$1 = JsxRuntime.jsx(DashboardProductEditorUnarchiveLink.make, {
                productId: product.id,
                onUnarchived: (function (product) {
                    dispatch({
                          TAG: "UpdateProduct",
                          _0: product
                        });
                  })
              });
          break;
      
    }
    if (exit === 1) {
      tmp$1 = JsxRuntime.jsx(DashboardProductEditorContextMenu.make, {
            product: state.product,
            onUpdated: (function (product) {
                dispatch({
                      TAG: "UpdateProduct",
                      _0: product
                    });
              })
          });
    }
    var match$4 = state.status;
    tmp = JsxRuntime.jsx(DashboardProductEditorLocationTab.make, {
          locations: locations,
          selectedLocation: state.location,
          selectLocation: (function (id) {
              dispatch({
                    TAG: "SelectLocation",
                    _0: id
                  });
            }),
          children: JsxRuntime.jsxs(DashboardProductEditorActionBar.make, {
                children: [
                  JsxRuntime.jsx(DashboardProductEditorActionBar.ContextControls.make, {
                        children: tmp$1
                      }),
                  JsxRuntime.jsx(DashboardProductEditorActionBar.$$Error.make, {
                        children: state.submissionError ? "Something went wrong" : null
                      }),
                  JsxRuntime.jsx(Button.make, {
                        size: "LG",
                        color: "Primary",
                        expanded: true,
                        visuallyDisabled: Belt_Option.isNone(state.location),
                        busy: match$4 !== "Editing",
                        onClick: (function (param) {
                            dispatch("Submit");
                          }),
                        children: "Save changes"
                      })
                ]
              })
        });
  } else {
    tmp = JsxRuntime.jsx(DashboardProductEditorNoLocations.make, {
          providerUrl: props.providerUrl
        });
  }
  return JsxRuntime.jsxs(DashboardProductEditorLayout.make, {
              children: [
                JsxRuntime.jsx(DashboardProductEditorHeader.make, {
                      product: product,
                      active: "LocationTab"
                    }),
                tmp
              ]
            });
}

var DashboardEditProductLocationTab = {
  make: DashboardEditProductLocationTab$DashboardEditProductLocationTab
};

function DashboardEditProductLocationTab$default(props) {
  var props$1 = props.props;
  return JsxRuntime.jsx(DashboardEditProductLocationTab$DashboardEditProductLocationTab, {
              product: ColocationProduct.Draft.fromJs(props$1.product),
              locations: Belt_Array.map(props$1.locations, $$Location.fromJs),
              providerUrl: props$1.providerUrl
            });
}

var $$default = DashboardEditProductLocationTab$default;

export {
  DashboardEditProductLocationTab ,
  $$default as default,
}
/* Api Not a pure module */
