// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../styleguide/components/Heading/H2.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as React from "react";
import * as Container from "../../../styleguide/components/Container/Container.res.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as SearchField from "../../../styleguide/forms/SearchField/SearchField.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardProductEditorScss from "./DashboardProductEditor.scss";
import * as DashboardProductEditorLocations from "./DashboardProductEditorLocations.res.js";

var css = DashboardProductEditorScss;

function DashboardProductEditorLocationTab(props) {
  var locations = props.locations;
  var searchLocations = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "SearchLocations",
                _0: param.state.input
              });
        }));
  var initialState = React.useMemo((function () {
          return {
                  input: "",
                  locations: locations
                };
        }), []);
  var match = Hooks.useReducer(initialState, (function (state, action) {
          switch (action.TAG) {
            case "UpdateInput" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          input: action._0,
                          locations: state.locations
                        },
                        _1: searchLocations
                      };
            case "SearchLocations" :
                var input = action._0;
                if (input === "") {
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              param.dispatch({
                                    TAG: "SetLocations",
                                    _0: input,
                                    _1: locations
                                  });
                            })
                        };
                } else {
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              param.dispatch({
                                    TAG: "SetLocations",
                                    _0: input,
                                    _1: Belt_Array.keep(locations, (function ($$location) {
                                            var input$1 = input.toLowerCase();
                                            if ((function (__x) {
                                                    return Js_string.includes(input$1, __x);
                                                  })($$location.name.toLowerCase()) || (function (__x) {
                                                    return Js_string.includes(input$1, __x);
                                                  })($$location.streetAddress1.toLowerCase()) || (function (__x) {
                                                    return Js_string.includes(input$1, __x);
                                                  })($$location.streetAddress2.toLowerCase()) || (function (__x) {
                                                    return Js_string.includes(input$1, __x);
                                                  })($$location.city.toLowerCase()) || (function (__x) {
                                                    return Js_string.includes(input$1, __x);
                                                  })($$location.state.toLowerCase())) {
                                              return true;
                                            } else {
                                              var __x = $$location.country.toLowerCase();
                                              return Js_string.includes(input$1, __x);
                                            }
                                          }))
                                  });
                            })
                        };
                }
            case "SetLocations" :
                if (action._0 === state.input) {
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            locations: action._1
                          }
                        };
                } else {
                  return "NoUpdate";
                }
            
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  return JsxRuntime.jsxs(Container.make, {
              className: css.locationsContainer,
              children: [
                JsxRuntime.jsx(H2.make, {
                      className: css.formTitle,
                      children: "Set Location"
                    }),
                JsxRuntime.jsx(SearchField.make, {
                      id: "product-form--location",
                      value: state.input,
                      placeholder: "Search by title or address",
                      autoFocus: true,
                      onChange: (function ($$event) {
                          dispatch({
                                TAG: "UpdateInput",
                                _0: $$event.target.value
                              });
                        })
                    }),
                JsxRuntime.jsx(DashboardProductEditorLocations.make, {
                      locations: state.locations,
                      selectedLocation: props.selectedLocation,
                      selectLocation: props.selectLocation
                    }),
                props.children
              ]
            });
}

var make = DashboardProductEditorLocationTab;

export {
  css ,
  make ,
}
/* css Not a pure module */
