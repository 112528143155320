// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../libs/ID.res.js";
import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardProductEditorScss from "./DashboardProductEditor.scss";
import * as DashboardProductEditorLocationWidget from "./DashboardProductEditorLocationWidget.res.js";

var css = DashboardProductEditorScss;

var make = React.memo(function (props) {
      var selectLocation = props.selectLocation;
      var selectedLocation = props.selectedLocation;
      return JsxRuntime.jsx("div", {
                  children: Belt_Array.map(props.locations, (function ($$location) {
                          return JsxRuntime.jsx(DashboardProductEditorLocationWidget.make, {
                                      location: $$location,
                                      selected: Belt_Option.getWithDefault(Belt_Option.map(selectedLocation, (function (id) {
                                                  return Caml_obj.equal($$location.id, id);
                                                })), false),
                                      select: selectLocation
                                    }, ID.toString($$location.id));
                        })),
                  className: css.locations
                });
    });

export {
  css ,
  make ,
}
/* css Not a pure module */
