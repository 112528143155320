// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../styleguide/components/Heading/H2.res.js";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as Container from "../../../styleguide/components/Container/Container.res.js";
import * as IconLocation from "../../../styleguide/icons/IconLocation.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardProductEditorScss from "./DashboardProductEditor.scss";

var css = DashboardProductEditorScss;

function DashboardProductEditorNoLocations(props) {
  return JsxRuntime.jsxs(Container.make, {
              className: css.noLocations,
              children: [
                JsxRuntime.jsx(H2.make, {
                      children: "No Locations"
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(IconLocation.make, {
                            size: "XXL",
                            color: "LighterGray"
                          }),
                      className: css.locationIcon
                    }),
                JsxRuntime.jsx("div", {
                      children: "You should create a location first. After that you can go back here. Your progress will be saved."
                    }),
                JsxRuntime.jsx(Button.AsLink.make, {
                      href: props.providerUrl,
                      size: "LG",
                      color: "Teal",
                      children: "Create First Location"
                    })
              ]
            });
}

var make = DashboardProductEditorNoLocations;

export {
  css ,
  make ,
}
/* css Not a pure module */
