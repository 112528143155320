// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../styleguide/components/Link/A.res.js";
import * as ID from "../../../libs/ID.res.js";
import * as React from "react";
import * as Events from "../../../libs/Events.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RadioButton from "../../../styleguide/forms/RadioButton/RadioButton.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardProductEditorScss from "./DashboardProductEditor.scss";

var css = DashboardProductEditorScss;

function filterLinkClicks(target, link, fn) {
  if (link !== undefined && target === Caml_option.valFromOption(link)) {
    return ;
  } else {
    return fn();
  }
}

var Handlers = {
  filterLinkClicks: filterLinkClicks
};

function DashboardProductEditorLocationWidget(props) {
  var select = props.select;
  var $$location = props.location;
  var linkEl = React.useRef(null);
  return JsxRuntime.jsx("button", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(RadioButton.make, {
                            id: "radioButton--" + ID.toString($$location.id),
                            checked: props.selected,
                            tabIndex: -1,
                            onChange: (function (prim) {
                                
                              })
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("span", {
                                    children: $$location.name,
                                    className: css.locationName
                                  }),
                              JsxRuntime.jsx("span", {
                                    children: (function (__x) {
                                          return Js_array.joinWith(", ", __x);
                                        })(Belt_Array.keep([
                                              $$location.streetAddress1,
                                              $$location.streetAddress2,
                                              $$location.city,
                                              $$location.state,
                                              $$location.country
                                            ], (function (item) {
                                                return item !== "";
                                              }))),
                                    className: css.locationAddress
                                  })
                            ],
                            className: css.locationWidgetInfo
                          }),
                      JsxRuntime.jsx(A.make, {
                            href: $$location.editUrl,
                            targetBlank: true,
                            className: css.locationDetailsLink,
                            elRef: Caml_option.some(linkEl),
                            children: "Location Details"
                          })
                    ],
                    className: css.locationWidgetInner
                  }),
              className: css.locationWidget,
              type: "button",
              onClick: (function ($$event) {
                  filterLinkClicks(Events.Target.fromReactEventTargetToHtmlElement($$event.target), Caml_option.nullable_to_opt(linkEl.current), (function () {
                          select($$location.id);
                        }));
                })
            });
}

var make = DashboardProductEditorLocationWidget;

export {
  css ,
  Handlers ,
  make ,
}
/* css Not a pure module */
